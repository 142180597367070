@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 스크롤바 */
.langsa-scrollType-1::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
/* 스크롤바 막대 설정*/
.langsa-scrollType-1::-webkit-scrollbar-thumb {
  background: linear-gradient(#d4d4d4, #d4d4d4);
  /* background: linear-gradient(#86dd8a, #cfe7d9); */
  border-radius: 5px;
}
/* 스크롤바 뒷 배경 설정*/
.langsa-scrollType-1::-webkit-scrollbar-track {
  display: none;
  background: #d9d9d9;
  /* background: #eeeeee; */
  border-radius: 5px;
}
